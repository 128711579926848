var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container--fluid"},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('app-widget',{attrs:{"title":"Basic Usage"}},[_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('div',{staticClass:"text-center flex-grow-1 align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Button ")])]}}])},[_c('span',[_vm._v("Tooltip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")])]}}])},[_c('span',[_vm._v("Tooltip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("This text has a tooltip")])]}}])},[_c('span',[_vm._v("Tooltip")])])],1)])])],1),_c('v-col',{attrs:{"lg":"6"}},[_c('app-widget',{attrs:{"title":"Alignment"}},[_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","color":"primary"}},on),[_vm._v(" Left ")])]}}])},[_c('span',[_vm._v("Left tooltip")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","color":"primary"}},on),[_vm._v(" Top ")])]}}])},[_c('span',[_vm._v("Top tooltip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","color":"primary"}},on),[_vm._v(" Bottom ")])]}}])},[_c('span',[_vm._v("Bottom tooltip")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","color":"primary"}},on),[_vm._v(" Right ")])]}}])},[_c('span',[_vm._v("Right tooltip")])])],1)])])],1),_c('v-col',{attrs:{"lg":"6"}},[_c('app-widget',{attrs:{"title":"Icon Badge"}},[_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('v-container',{staticClass:"text-center container--fluid"},[_c('v-row',{staticClass:"flex",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{nativeOn:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v(" toggle ")])],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-cart ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v("Programmatic tooltip")])])],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }